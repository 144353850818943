import React from 'react';
import c from 'classnames';
import loadable from '@loadable/component';

const ContentComonents = ({ components, containsPageHeader }) => {
  const allowedContentItems = {
    ContentfulComponentVideoTeaser: loadable(() => import('./VideoTeaser')),
    ContentfulComponentPageHeader: loadable(() => import('./PageHeader')),
    ContentfulComponentFullwidthImageTeaser: loadable(() => import('./FullwidthImageTeaser')),
    ContentfulComponentFullwidthTextTeaser: loadable(() => import('./FullwidthTextTeaser')),
    ContentfulComponentText: loadable(() => import('./Text')),
    ContentfulComponentImageContentTeaser: loadable(() => import('./ImageContentTeaser')),
    ContentfulComponentImageSliderContentTeaser: loadable(() => import('./ImageSliderContentTeaser')),
    ContentfulComponentPartnerTeaser: loadable(() => import('./PartnerTeaser')),
    ContentfulComponentAccordionTeaser: loadable(() => import('./AccordionTeaser')),
    ContentfulComponentTrainingPartnerTeaser: loadable(() => import('./TrainingPartnerTeaser')),
    ContentfulComponentQuoteTeaser: loadable(() => import('./QuoteTeaser')),
    ContentfulComponentIframe: loadable(() => import('./Iframe')),
  };
  return (
    <div className={c({ 'pt-200': !containsPageHeader })}>
      {components.map((contentItem, index) => {
        if (!contentItem.internal) return false;
        if (!(contentItem.internal.type in allowedContentItems)) return false;
        const ContentItem = allowedContentItems[contentItem.internal.type];
        // eslint-disable-next-line react/jsx-props-no-spreading
        return (
          <section data-name={contentItem.internal.type.split('ContentfulComponent')[1]} key={contentItem.id}>
            <ContentItem
              {...contentItem}
              prevItem={components[index - 1]?.internal?.type}
              nextItem={components[index + 1]?.internal?.type}
            />
          </section>
        );
      })}
    </div>
  );
};

export default ContentComonents;
