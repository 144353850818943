import React from 'react';
import * as styles from './styles.module.scss';

type PreviewMessageProps = {};

const PreviewMessage: React.FC<PreviewMessageProps> = () => {
  return <div className={styles.container}>Du befindest dich im Vorschau-Modus</div>;
};

export default PreviewMessage;
