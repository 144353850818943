/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useState } from 'react';
import * as contentful from 'contentful';
import ContentComonents from '../components/cms';
import Layout from '../modules/Layout';
import PreviewMessage from '../modules/PreviewMessage';
import PreviewContext from '../provider/previewContext';

type PreviewProps = {
  location: {
    search: string;
  };
};

const client = contentful.createClient({
  space: process.env.CONTENTFUL_SPACE_ID || '',
  accessToken: process.env.CONTENTFUL_PREVIEW_TOKEN || '',
  host: 'preview.contentful.com',
});

const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const Preview: React.FC<PreviewProps> = props => {
  const [data, setData] = useState([]);
  const { search } = props.location;
  const id = search.split('?id=')[1];

  useEffect(() => {
    if (id) {
      client.getEntry(id, { include: 8 }).then((entry: any) => {
        const { fields } = entry;
        entry.fields.content.map((item: any, index: Number) => {
          const tmpFields = item.fields;

          for (const [key, value] of Object.entries(item.fields)) {
            // resolve rich text
            if (value.nodeType) {
              tmpFields[key].raw = JSON.stringify(value);
              delete tmpFields[key].content;
              delete tmpFields[key].data;
              delete tmpFields[key].nodeType;
            }

            // resolve images
            if (value.metadata) {
              tmpFields[key] = value.fields;
              for (const [key1, value1] of Object.entries(value.fields)) {
                if (value1) {
                  tmpFields[key][key1] = value1.fields;
                }
              }
            }

            if (Array.isArray(value)) {
              const tmpArr = [];
              value.map(itemArr => {
                const tmpArrItem = itemArr.fields;
                for (const [keyArr, valueArr] of Object.entries(itemArr.fields)) {
                  if (!valueArr) return;
                  // resolve rich text
                  if (valueArr.nodeType) {
                    tmpArrItem[keyArr].raw = JSON.stringify(valueArr);
                    delete tmpArrItem[keyArr].content;
                    delete tmpArrItem[keyArr].data;
                    delete tmpArrItem[keyArr].nodeType;
                  }
                }
                tmpArr.push(tmpArrItem);
              });
              tmpFields[key] = tmpArr;

              console.log(value)
            }
          }

          fields.content[index] = tmpFields;
          fields.content[index].internal = { type: `Contentful${capitalize(item.sys.contentType.sys.id)}` };
        });
        setData(fields);
      });
    }
  }, []);

  if (!id) return null;

  if (!data || data.length < 1) return null;

  return (
    <PreviewContext.Provider value>
      <Layout pageName={`Vorschau - ${data.title}`} description={data.seoDescription} isHome hideHeader>
        <ContentComonents components={data.content} containsPageHeader />
        <PreviewMessage />
      </Layout>
    </PreviewContext.Provider>
  );
};

export default Preview;
